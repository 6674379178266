.custom-view-wrapper
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 160px 30px 0px 30px;
}

@media screen and (max-width: 800px)
{
    .custom-view-wrapper
    {
        padding-top: 110px;
    }
}