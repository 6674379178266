.footer-wrapper
{
    height: 504px;
    width: 100%;

    display: flex;

    margin-top: 30px;
    
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    text-align: center;

    padding: 100px 120px 30px 120px;

    background-color: white;
}

.footer-wrapper > p
{
    color: #00000075;

    font-size: 17px;

    line-height: 30px;

    font-family: "Next Medium";
}

.footer-wrapper > p > a
{
    text-decoration: underline;

    cursor: pointer;

    color: #00000075;

    font-size: 17px;

    line-height: 30px;

    font-family: "Next Medium";
}

.footer-wrapper > div
{
    height: 100%;
    width: 100%;

    margin-bottom: 50px;
    
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.footer-left
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.logo
{
   margin-left: 100px;
}

.footer-center
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.footer-center > div
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.mobile-logo
{
    display: none;
}

.footer-center > div > button:nth-child(1)
{
    height: 59px;
    width: 167px;

    border-radius: 6px;

    border: none;

    color: white;

    font-size: 21px;

    font-family: "Next Demi Bold";

    line-height: 29px;

    cursor: pointer;

    margin-right: 30px;

    background-color: orange;
}

.footer-center > div > button:nth-child(2)
{
    height: 59px;
    width: 167px;

    border-radius: 6px;

    border: none;

    color: black;

    font-size: 19px;

    font-family: "Next Demi Bold";

    line-height: 29px;

    background: none;

    cursor: pointer;
}

.footer-right
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.footer-nav-link
{
    text-decoration: none;

    color: #00000051;

    font-size: 21px;

    line-height: 29px;

    margin-bottom: 30px;
}

.footer-nav-link:hover
{
    color: #FA8E20;
}

@media screen and (max-width: 1300px)
{
    .footer-wrapper
    {
        height: auto;

        padding: 45px 0px 25px 0px;
    }

    .footer-wrapper > p
    {
        font-size: 16px;
    }

    .footer-wrapper > div
    {
        height: 100%;
        width: 100%;

        flex-direction: column;
    }

    .footer-left
    {
        align-items: center;
    }

    .footer-center
    {
        order: -1;

        margin-bottom: 50px;
    }

    .mobile-logo
    {
        display: block;

        margin-left: 80px;
    }

    .logo
    {
        display: none;
    }

    .footer-center > div
    {
        margin-top: 50px;

        justify-content: center;
    }

    .footer-center > div > button:nth-child(1)
    {
        height: 54px;

        margin-right: 0px;

        font-size: 16px;
    }

    .footer-center > div > button:nth-child(2)
    {
        height: 54px;

        font-size: 16px;
    }

    .footer-right
    {
        align-items: center;
    }

    .footer-nav-link
    {
        font-size: 16px;

        margin-bottom: 15px;
    }

    .footer-wrapper > p
    {
        max-width: 80%;
    }
}