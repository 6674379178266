

@font-face {
    font-family: 'Futura-Bold';
    src: url('../../fonts/futura_bold_font-webfont.woff2') format('woff2'),
         url('../../fonts/futura_bold_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Futura';
    src: url('../../fonts/futura_book_font-webfont.woff2') format('woff2'),
         url('../../fonts/futura_book_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuralight';
    src: url('../../fonts/futura_light_bt-webfont.woff2') format('woff2'),
         url('../../fonts/futura_light_bt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuralight';
    src: url('../../fonts/futura_light_font-webfont.woff2') format('woff2'),
         url('../../fonts/futura_light_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuramedium';
    src: url('../../fonts/futura_medium_bt-webfont.woff2') format('woff2'),
         url('../../fonts/futura_medium_bt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'futuramedium_italic';
    src: url('../../fonts/futura_medium_italic_font-webfont.woff2') format('woff2'),
         url('../../fonts/futura_medium_italic_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

header
{
    height: 123px;
    width: 100vw;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding: 0px 40px;

    position: fixed;

    z-index: 2;

    background-color: white;
}

header > svg
{
    cursor: pointer;
}

header > nav
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: flex-end;
    align-items: center;
}

.register-button
{
    height: 59px;
    width: 173px;

    border-radius: 6px;

    background: none;

    font-size: 21px;

    line-height: 29px;

    font-weight: bold;

    border: 0.5px solid #C3C3C3;

    cursor: pointer;
}

.register-button:hover
{
    border: none;

    color: white;

    background-color: orange;
}

.register-menu-button
{
    height: 59px;
    width: 173px;

    border-radius: 6px;

    background: none;

    font-size: 18px;

    line-height: 29px;

    font-weight: bold;

    border: 0.5px solid #C3C3C3;

    cursor: pointer;
}

.register-menu-button:hover
{
    border: none;

    color: white;

    background-color: orange;
}

.menu-button
{
    height: 37px;
    width: 80px;

    border-radius: 6px;

    background-color: #FFF5EB;

    border: none;

    color: orange;

    font-size: 15px;

    font-family: "Next Demi Bold";

    cursor: pointer;

    display: none;
}

.header-menu-wrapper
{
    height: calc(100vh - 123px);
    width: 100vw;

    z-index: 999;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: absolute !important;
    top: 123px;
    left: 0px;

    background-color: white;
}

.header-link
{
    font-size: 18px;

    line-height: 25px;

    font-family: "Next Demi Bold";

    text-decoration: none;

    margin-right: 80px;

    color: #00000050;
}

.header-link:hover
{
    color: #FA8E20;
}

.header-link-active
{
    font-size: 18px;

    line-height: 25px;

    text-decoration: none;

    font-family: "Next Demi Bold";

    color: #000000;
}

.header-link-active:hover
{
    color: black;
}

.header-profile
{
    display: flex;

    justify-content: center;
    align-items: center;

    cursor: pointer;

    position: relative;
}

.header-profile > div
{
    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    border-left: 1px solid #B7B7B7;

    width: auto;

    padding-left: 70px;
    padding-right: 30px;
}

.header-profile > div > p:nth-child(1)
{
    font-family: "Next Demi Bold";

    font-size: 21px;

    line-height: 29px;
}

.header-profile > div > p:nth-child(2)
{
    font-family: "Next Medium";
    
    font-size: 16px;

    line-height: 22px;

    color: #B7B7B7;
}

.header-profile > img
{
    height: 59px;
    width: 59px;

    border-radius: 6px;

    object-fit: cover;
}

@media screen and (max-width: 800px)
{
    header
    {
        height: 92px;

        padding: 0px 20px;
    }

    .header-menu-wrapper
    {
        height: calc(100vh - 92px);

        top: 92px;
    }

    .register-button
    {
        display: none;
    }
}

@media screen and (min-width: 800px)
{
    .header-menu-wrapper
    {
        display: none;
    }
}

@media screen and (max-width: 1300px)
{
    header > nav
    {
        display: none;
    }

    .menu-button
    {
        display: block;
    }

    .header-link
    {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .header-profile
    {
        position: absolute;

        bottom: 20px;
    }

    .header-profile > div
    {
        border: none;

        padding-left: 0px;
    }
}