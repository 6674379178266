.message-information
{
    height: 100%;

    max-width: 400px;
    min-width: 400px;

    border-left: 1px solid #C4C4C4;

    display: flex;

    justify-content: space-between;
    align-self: center;
    flex-direction: column;

    overflow-x: scroll;
}

.information-top
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    padding: 0px 15px 15px 30px;
}

.information-top > div:nth-child(1)
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    font-size: 30px;

    line-height: 41px;

    color: #343434;

    font-family: 'Next Demi Bold';
}

.information-top > div:nth-child(1) > button
{
    height: 43px;
    width: 43px;

    border: 1px solid #000000;

    cursor: pointer;

    background: none;

    border-radius: 8px;

    display: none;
}

.information-top > div:nth-child(1) > button:hover
{
    border: 1px solid #FA8E20;
}

.information-top > *
{
    margin-bottom: 15px;
}

.information-top > img:nth-child(2)
{
    height: 210px;
    width: 100%;

    border-radius: 7px;
}

.information-top > p:nth-child(3)
{
    font-size: 21px;

    line-height: 29px;

    color: #343434;

    font-family: 'Next Demi Bold';
}

.information-top > p:nth-child(4)
{
    font-size: 18px;

    line-height: 25px;

    color: #A0A0A0;

    font-family: 'Next Regular';
}

.information-top > button:nth-child(5)
{
    border: none;

    background: none;

    font-size: 19px;

    line-height: 26px;

    color: #000000;

    cursor: pointer;

    font-family: 'Next Demi Bold';
}

.information-top > button:nth-child(5):hover
{
   color: #FA8E20;
}

.information-bottom
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
}

.information-bottom > button
{
    height: 59px;
    width: 189px;

    background: none;

    border-radius: 6px;

    font-size: 17px;

    cursor: pointer;

    line-height: 23px;

    color: #343434;

    font-family: 'Next Demi Bold';

    border: 1px solid #343434;
}

.information-bottom > button:hover
{
    color: #FA8E20;
}

.information-bottom > p
{
    font-size: 17px;

    line-height: 23px;

    color: #343434;

    cursor: pointer;

    font-family: 'Next Medium';

    text-decoration: underline;
}

.information-bottom > p:hover
{
    color: red;
}

@media screen and (max-width: 1300px)
{
    .message-information
    {
        display: none;
    }
}

@media screen and (max-width: 800px)
{
    .message-information
    {
        border: none;

        display: flex;

        min-width: 0px;
        max-width: none;
    }

    .information-top > div:nth-child(1) > button
    {
        display: flex;

        justify-content: center;
        align-items: center;
    }

    .information-top
    {
        padding: 5px;
    }

    .information-bottom > button
    {
        width: 150px;
    }
}