.create-view-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    padding: 22px;
}

@media screen and (max-width: 800px)
{
    .create-view-wrapper
    {
        background-color: white;
    }
}