.realisation-wrapper
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    padding: 0px 60px;

    margin-bottom: 40px;
}

.realisation-wrapper > p
{
    font-size: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;

    margin-bottom: 20px;
}

.realisation-wrapper > div
{
    height: auto;
    width: 100%;

    overflow-x: scroll;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding: 20px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.050));

    border-radius: 8px;

    background-color: white;
}

.realisation-wrapper > div > div 
{
    height: 300px;
    width: 450px;

    padding: 10px;

    display: flex;

    justify-content: center;
    align-items: center;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.050));

    margin-right: 20px;

    border-radius: 8px;
}

.realisation-wrapper > div > div > img
{
    height: 100%;
    width: 437px;

    object-fit: contain;

    border-radius: 7px;
}

@media screen and (max-width: 1300px)
{
    .realisation-wrapper
    {
        padding: 0px 10px;
    }

    .realisation-wrapper > p
    {
        font-size: 20px;

        line-height: 27px;
    }

    .realisation-wrapper > div
    {
        height: auto;

        padding: 0px;

        background: none;
    }

    .realisation-wrapper > div > div
    {
        height: 190px;
        width: 280px;

        background-color: white;
    }

    .realisation-wrapper > div > div > img
    {
        height: 100%;
        width: 250px;

        object-fit: cover;
    }
}