.comment-list-wrapper
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comment-list-header
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding: 0px 60px;

    margin-bottom: 20px;
}

.comment-list-header > p:nth-child(1)
{
    font-size: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;
}

.comment-list-header > p:nth-child(2)
{
    font-size: 19px;

    font-family: 'Next Demi Bold';

    line-height: 26px;

    cursor: pointer;
}

.comment-list-header > p:nth-child(2):hover
{
    color: #FA8E20;
}

.comment-list
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding: 0px 60px;

    margin-bottom: 20px;

    overflow-x: scroll;
}

.comment-wrapper
{
    height: 334px;
    width: 472px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.020));

    min-width: 472px;

    position: relative;

    margin-right: 20px;

    border-radius: 7px;

    padding: 30px 30px 20px 30px;

    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    background-color: white;
}

.comment-wrapper > p
{
    font-size: 17px;

    font-family: 'Next Medium';

    text-decoration: underline;

    line-height: 23px;

    cursor: pointer;
}

.comment-wrapper > p:hover
{
    color: red;
}

.comment-loading
{
    height: 100%;
    width: 100%;

    position: absolute;

    top: 0px;
    left: 0px;

    display: flex;

    justify-content: center;
    align-items: center;

    background-color: white;
}

.comment-profile
{
    height: auto;
    width: auto;

    display: flex;

    padding-bottom: 20px;

    margin-bottom: 10px;

    border-bottom: 1px solid #D8D8D8;

    justify-content: flex-start;
    align-items: center;
}

.comment-profile > div:nth-child(1)
{
    height: 77px;
    width: 77px;

    min-width: 77px;

    border-radius: 9px;

    margin-right: 30px;

    background-color: whitesmoke;
}

.comment-profile > div:nth-child(1) > img
{
    height: 100%;
    width: 100%;

    border-radius: 7px;

    object-fit: cover;
}

.comment-profile > div:nth-child(2)
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.comment-profile > div:nth-child(2) > p
{
    font-size: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;

    white-space: nowrap;

    text-overflow: ellipsis;
}

.message-comment
{
    height: 100%;
    width: 90%;

    padding-top: 10px;

    margin-bottom: 10px;

    font-size: 20px;

    font-family: 'Next Regular';

    line-height: 27px;

    color: #A0A0A0;

    overflow-y: hidden;
}

@media screen and (max-width: 1300px)
{
    .comment-profile > div:nth-child(1)
    {
        margin-right: 10px;
    }

    .comment-list-header
    {
        padding: 0px 10px;
    }

    .comment-list-header > p:nth-child(1)
    {
        font-size: 20px;

        font-family: 'Next Demi Bold';

        line-height: 27px;
    }

    .comment-wrapper
    {
        height: 248px;
        width: 280px;

        padding: 10px;

        min-width: 280px;

        background-color: white;
    }

    .comment-profile > div:nth-child(2) > p
    {
        font-size: 17px;

        line-height: 23px;
    }

    .comment-profile > div:nth-child(1)
    {
        height: 45px;
        width: 45px;

        min-width: 45px;
    }

    .comment-list
    {
        padding: 0px 10px;
    }

    .message-comment
    {
        font-size: 16px;

        line-height: 22px;
    }

    .comment-wrapper > p
    {
        font-size: 13px;

        line-height: 18px;
    }
}