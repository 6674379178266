.message-item
{
    height: auto;
    width: 95%;

    padding: 10px 20px;

    margin-bottom: 15px;

    display: flex;

    align-items: flex-start;
}

.message-item-other
{
    justify-content: flex-start;
}

.message-item-me
{
    justify-content: flex-start;
    flex-direction: row-reverse;
}

.message-content
{
    height: auto;
    width: auto;

    min-height: 55px;

    cursor: pointer;

    max-width: 50%;

    padding: 15px 20px;

    display: flex;

    justify-content: center;
    align-items: center;

    border-radius: 7px;

    font-size: 17px;

    line-height: 23px;

    font-family: 'Next Regular';
}

.message-content > img
{
    height: 100px;
    width: 100px;

    cursor: pointer;

    object-fit: cover;

    border-radius: 8px;
}

.message-content-other
{
    color: #A0A0A0;

    margin-left: 20px;
    margin-right: 20px;

    background-color: #F7F7F7;
}

.message-content-me
{
    color: white;

    margin-right: 20px;
    margin-left: 20px;

    background-color: #FA8E20;
}

.option-message
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: center;
    align-items: center;

    position: relative;
}

.option-message > svg
{
    align-self: center;

    cursor: pointer;

    margin-right: 10px;
    margin-left: 10px;
}

.option-message > svg:hover
{
    fill: #FA8E20;
}

@media screen and (max-width: 800px)
{
    .message-content
    {
        font-size: 16px;

        line-height: 20px;
    }

    .message-item
    {
        padding-left: 0px;
        padding-right: 0px;
    }

    .message-item > div:nth-child(1)
    {
        display: none;
    }
}