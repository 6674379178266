.messages-view-wrapper
{
    height: 100%;
    width: 100%;

    padding: 150px 50px 30px 50px;
}

.messages-section
{
    height: 100%;
    width: 100%;

    border-radius: 15px;

    padding: 20px 20px 20px 0px;

    display: flex;

    overflow-y: scroll;

    justify-content: center;
    align-items: center;

    background-color: white;
}

.no-message-warning
{
    font-size: 20px;

    font-family: "Next Medium";

    font-size: 17px;

    text-align: center;
}

.message-channel-empty
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.message-channel-list
{
    height: 100%;

    width: 40%;

    min-width: 100px;

    overflow-x: scroll;

    border-right: 1px solid #C4C4C4;
}

.message-channel-list > p
{
    font-size: 30px;

    margin-left: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;
}

.message-chat
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.message-chat > section
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding-bottom: 100px;
    padding-top: 20px;

    overflow: scroll;
}

@media screen and (max-width: 1500px)
{
    .message-channel-list
    {
        width: 10%;

        padding-top: 8px;
    }
}

@media screen and (max-width: 1500px) and (min-width: 800px)
{
    .message-channel-list > p
    {
        display: none;
    }
}

@media screen and (max-width: 800px)
{
    .message-chat > section
    {
        padding-bottom: 70px;
    }

    .messages-view-wrapper
    {
        padding: 105px 10px 10px 10px;
    }

    .messages-section
    {
        border-radius: 5px;

        padding-left: 10px;
        padding-right: 10px;
    }

    .message-channel-list
    {
        width: 100%;

        border: none;

        padding-top: 8px;
    }

    .message-information
    {
        width: 100%;

        border: none;
    }
}