.comment-modal-wrapper
{
    height: 100%;
    width: 100%;

    max-width: 562px;
    max-height: 580px;

    border-radius: 5px;

    padding: 30px;

    background-color: white;
}

.comment-modal-wrapper > div:nth-child(1)
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding-bottom: 20px;

    margin-bottom: 20px;

    font-size: 21px;

    line-height: 29px;

    font-family: 'Next Demi Bold';

    border-bottom: 1px solid #D6D6D6;
}

.comment-modal-wrapper > div:nth-child(1) > svg
{
    cursor: pointer;
}

.comment-modal-wrapper > div:nth-child(2) > div
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.comment-modal-wrapper > div:nth-child(2) > *
{
    margin-bottom: 20px;
}

.comment-modal-wrapper > div:nth-child(2) > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    line-height: 25px;

    color: #88888865;
}

.comment-modal-wrapper > div:nth-child(2) > textarea
{
    height: 150px;
    width: 100%;

    border-radius: 7px;

    border: none;

    background-color: #F7F7F7;

    outline: none;

    padding: 20px;

    resize: none;

    font-size: 20px;

    font-family: 'Next Medium';

    line-height: 27px;
}

.comment-modal-wrapper > div:nth-child(2) > textarea::placeholder
{
    font-size: 20px;

    font-family: 'Next Medium';

    line-height: 27px;

    color: #88888865;
}

@media screen and (max-width: 900px)
{
    .comment-modal-wrapper
    {
        max-width: 100%;
        max-height: 100%;
    }
}