.subscription-container {
    width: 300px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .subscription-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* .subscription-container p {
    font-size: 14px;
    color: #666;
  } */
  
  .plans {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .plan {
    width: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
  }
  
  .plan.selected {
    border-color: #FA8E20;
    background-color: #fff0ec;
  }
  
  .plan h3 {
    margin: 10px 0;
    font-size: 20px;
    color: #333;
  }
  
  .payment-inputs {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .payment-inputs input {
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .subscribe-button {
    width: 100%;
    padding: 15px;
    background-color: #ff7058;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .subscribe-button:hover {
    background-color: #ff4e3b;
  }
  