.stripe-modal-wrapper
{
    height: auto;
    width: 100%;

    max-width: 562px;
    max-height: 632px;

    border-radius: 5px;

    overflow: scroll;

    padding: 30px;

    text-align: center;

    background-color: white;
}

.stripe-modal-wrapper > p
{
    font-size: 25px;

    margin-bottom: 30px;

    font-family: "Next Medium";
}

#payment-message
{
    color: red;

    font-family: "Next Medium";

    margin-top: 20px;
}

.button-pay
{
    height: 59px;
    width: 100%;

    max-height: 59px;
    min-height: 59px;

    margin-top: 30px;

    border-radius: 6px;
    
    background-color: orange;

    color: white;

    border: none;

    font-size: 19px;
    line-height: 26px;

    font-family: "Next Demi Bold";

    cursor: pointer;
}