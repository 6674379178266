.grade
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 1300px)
{
    .grade > svg
    {
        height: 18px;
        width: 18px;
    }
}