.input-wrapper
{
    height: auto;
    width: 100%;
}

.input-wrapper > p
{
    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: "Next Medium";

    margin-bottom: 10px;
}

.hide-password-input > svg
{
    color: #AFAFAF;
}

.show-password-input > svg
{
    fill: #FA8E20;
}

.input-wrapper > div
{
    display: flex;

    justify-content: space-between;
    align-items: center;

    border-radius: 7px;

    padding-right: 20px;

    background-color: #F7F7F7;
}

.input-wrapper > div > div
{
   cursor: pointer;
}

.input-wrapper > div > input
{
    height: 64px;
    width: 85%;

    border-radius: 7px;

    border: none;

    color: black;

    font-size: 20px;

    line-height: 27px;

    outline: none;

    padding-left: 20px;

    font-family: "Next Medium";

    background-color: #F7F7F7;
}

.input-wrapper > input::placeholder
{
    color: #88888865;

    font-size: 20px;

    line-height: 27px;
}

@media screen and (max-width: 800px)
{
    .input-wrapper > input
    {
        height: 54px;

        font-size: 16px;

        line-height: 22px;
    }

    .input-wrapper > input::placeholder
    {
        font-size: 16px;

        line-height: 22px;
    }
}