.channel-item
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding: 15px 50px 15px 30px;

    margin-bottom: 15px;
    margin-top: 15px;

    cursor: pointer;
}

.channel-item-active
{
    background-color: #fee3bb80;
}

.channel-text
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    padding-left: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.channel-item > p
{
    font-size: 16px;

    line-height: 22px;

    color: #868686;

    font-family: "Next Medium";
}

.channel-text > p:nth-child(1)
{
    font-size: 21px;

    line-height: 29px;

    font-family: "Next Demi Bold";
}

.channel-text > p:nth-child(2)
{
    font-size: 17px;

    line-height: 23px;

    color: #A0A0A0;

    font-family: "Next Regular";
}

@media screen and (max-width: 1500px) and (min-width: 800px)
{
    .channel-item
    {
        padding: 10px;

        justify-content: center;
    }

    .channel-text
    {
        display: none;
    }

    .channel-item > p
    {
        display: none;
    }
}

@media screen and (max-width: 800px)
{
    .channel-text > p:nth-child(1)
    {
        font-size: 17px;

        line-height: 23px;
    }

    .channel-text > p:nth-child(2)
    {
        font-size: 16px;

        line-height: 22px;
    }
}