.chat-input
{
    height: 170px;
    width: 95%;

    border-top: 1px solid #C4C4C4;
}

.chat-input-top
{
    height: 85px;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    position: relative;

    padding: 0px 20px;
}

.chat-input-top > input
{
    display: none;
}

.chat-typings
{
    height: 120px;
    width: 100%;

    padding-left: 20px;

    position: absolute;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    bottom: 200px;
}

.chat-typings > p
{
    margin-left: 20px;

    font-size: 17px;

    font-family: 'Next Medium';
}

.chat-input-top-emoji
{
    position: absolute;

    top: -300px;
    right: 50px;
}

.chat-input-top-image
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding-left: 40px;
}

.chat-input-top-image > div > img
{
    height: 55px;
    width: 55px;

    border-radius: 5px;
}

.chat-input-top-image > div
{
    position: relative;

    display: flex;

    justify-content: center;
    align-items: center;
}

.chat-input-top-image > div > svg
{
    position: absolute;

    top: 1px;
    right: -13px;

    height: 20px;

    cursor: pointer;
}

.chat-input-top > svg
{
    cursor: pointer;
}

.chat-input-top > label
{
    cursor: pointer;
}

.chat-input-bottom
{
    height: 85px;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding: 0px 20px;
}

.chat-input-bottom > input
{
    height: 90%;
    width: 75%;

    margin: 0px 10px 0px 10px;

    padding-left: 10px;

    border: none;

    outline: none;

    font-size: 17px;

    line-height: 23px;

    font-family: 'Next Medium';
}

.chat-input-bottom > input::placeholder
{
    font-size: 17px;

    line-height: 23px;

    font-family: 'Next Medium';

    color: #AFAFAF;
}

.chat-input-bottom > button
{
    height: 59px;
    width: 151px;

    color: white;

    border: none;

    font-size: 19px;

    line-height: 26px;

    font-family: 'Next Demi Bold';

    border-radius: 6px;

    cursor: pointer;

    display: flex;

    justify-content: space-evenly;
    align-items: center;

    background-color: #FA8E20;
}

.chat-input-bottom > button:hover
{
    filter: brightness(110%);
}

@media screen and (max-width: 800px)
{
    .chat-input-top
    {
        padding: 0px;
    }

    .chat-input-bottom
    {
        padding: 0px;
    }

    .chat-input-bottom > button
    {
        width: 54px;
        height: 54px;
    }

    .chat-input-bottom > button > p
    {
        display: none;
    }

    .chat-typings
    {
        height: 70px;

        padding-left: 0px;
    }
}