.search-bar-wrapper
{
    height: 74px;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    margin-bottom: 30px;
    margin-top: 30px;
}

.secteur-select, .lieu-widget
{
    font-family: "Next Medium";

    font-size: 17px;
}

.search-bar-input-wrapper
{
    height: 74px;
    width: 55%;

    display: flex;

    justify-content: center;
    align-items: center;

    position: relative;

    border-radius: 8px;
}

.search-bar-input-wrapper > input
{
    height: 100%;
    width: 100%;

    border: none;

    font-size: 22px;

    line-height: 30px;

    color: black;

    text-align: center;

    font-family: "Next Medium";

    outline: none;

    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.search-bar-input-wrapper > input::placeholder
{
    color: #88888865;
}

.search-bar-input-wrapper > div
{
    display: flex;

    justify-content: space-evenly;
    align-items: center;
    padding : 0 6px;
    cursor: pointer;
}

.search-bar-input-wrapper > div > p:nth-child(1)
{
    display: flex;

    justify-content: space-evenly;
    align-items: center;

    font-size: 22px;

    line-height: 30px;

    font-family: "Next Medium";

    color: #88888865;

    cursor: pointer;
}

.secteur-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 22px;
    line-height: 30px;
    font-family: "Next Medium";
    color: #88888865;
    cursor: pointer;
}


.search-bar-input-wrapper > div:nth-child(5)
{
    height: 155px;
    width: 498px;

    padding: 20px;

    position: absolute;

    top: 80px;
    left: 0px;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    z-index: 2;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    background-color: white;
}

.search-bar-input-wrapper > div:nth-child(5) > div:nth-child(1)
{
    height: auto;
    width: 100%;

    padding-bottom: 10px;

    border-bottom: 1px solid #88888865;

    display: flex;

    justify-content: space-between;
    align-items: center;
}

.search-bar-input-wrapper > div:nth-child(5) > div:nth-child(1) > p:nth-child(1)
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: #B2B2B2;

    line-height: 23px;
}

.search-bar-input-wrapper > div:nth-child(5) > div:nth-child(1) > p:nth-child(2)
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: black;

    line-height: 23px;

    text-decoration: underline;
}

.search-bar-input-wrapper > div:nth-child(5) > div:nth-child(2)
{
    height: auto;
    width: 100%;

    overflow-y: scroll;
}

.search-bar-input-wrapper > div:nth-child(5) > div:nth-child(2) > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    color: black;

    margin-top: 10px;

    line-height: 25px;
}

.secteur-wrapper
{
    height: 100%;
    width: auto;

    min-width: 160px;

    border-left: 1px solid #D8D8D8;

    position: relative;

    background-color: white;
}

.secteur-wrapper > p
{
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
}

.secteur-wrapper > div
{
    height: auto;
    width: 309px;

    max-height: 223px;

    padding: 20px;

    position: absolute;

    top: 80px;
    left: 0px;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    z-index: 2;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    background-color: white;
}

.secteur-wrapper > div > div:nth-child(1)
{
    height: auto;
    width: 100%;

    padding-bottom: 10px;

    border-bottom: 1px solid #88888865;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.secteur-wrapper > div > div:nth-child(1) > svg
{
    color: #88888865;
}

.secteur-wrapper > div > div:nth-child(1) > input
{
    height: 100%;
    width: 100%;

    border: none;

    padding-left: 5px;

    outline: none;
}

.secteur-wrapper > div > div:nth-child(1) > input::placeholder
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: #88888865;

    line-height: 23px;
}

.secteur-wrapper > div > div:nth-child(2)
{
    height: 100%;
    width: 100%;

    overflow-y: scroll;
}

.secteur-wrapper > div > div:nth-child(2) > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    color: black;

    margin-top: 10px;

    line-height: 25px;
}

.lieu-wrapper
{
    height: 100%;
    width: auto;

    min-width: 125px;

    border-left: 1px solid #D8D8D8;

    position: relative;

    background-color: white;
}

.lieu-wrapper > p
{
    overflow: hidden;
    white-space: nowrap;
}

.lieu-wrapper > div
{
    height: 223px;
    width: 361px;

    padding: 20px;

    position: absolute;

    top: 80px;
    left: 0px;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    z-index: 2;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    background-color: white;
}

.lieu-wrapper  > div > div:nth-child(1)
{
    height: auto;
    width: 100%;

    padding-bottom: 10px;

    border-bottom: 1px solid #88888865;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.lieu-wrapper > div > div:nth-child(1) > svg
{
    color: #88888865;
}

.lieu-wrapper > div > div:nth-child(1) > input
{
    height: 100%;
    width: 100%;

    border: none;
    
    padding-left: 5px;

    outline: none;
}

.lieu-wrapper > div > div:nth-child(1) > input::placeholder
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: #88888865;

    line-height: 23px;
}

.rayon-wrapper
{
    height: 100%;
    width: auto;

    min-width: 130px;

    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    position: relative;

    border-left: 1px solid #D8D8D8;

    background-color: white;
}

.rayon-wrapper > div
{
    height: 113px;
    width: 294px;

    position: absolute;

    top: 80px;
    right: 0px;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    z-index: 2;

    padding: 0px 20px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    background-color: white;
}

.rayon-wrapper > div > div
{
    height: auto;
    width: 100%;

    border-bottom: 1px solid #88888865;

    padding-bottom: 10px;

    display: flex;

    justify-content: space-between;
    align-items: center;
}

.rayon-wrapper > div > div > p:nth-child(1)
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: #B2B2B2;

    line-height: 23px;
}

.rayon-wrapper > div > div > p:nth-child(2)
{
    font-size: 17px;

    font-family: 'Next Medium';

    color: black;

    line-height: 23px;
}

.search-bar-wrapper > button
{
    height: 74px;
    width: 184px;

    margin-left: 15px;

    background-color: #FA8E20;

    color: white;

    border: none;

    font-size: 22px;

    cursor: pointer;

    line-height: 30px;

    font-family: "Next Bold";

    border-radius: 8px;
}

.lieu-result-wrapper
{
    height: 100%;
    width: 100%;

    padding-top: 10px;

    overflow-x: scroll;
}


@media screen and (max-width: 1300px)
{
    .search-bar-wrapper
    {
        height: 50%;
        width: 100%;

        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .search-bar-input-wrapper
    {
        height: 100%;
        width: 100%;

        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .search-bar-input-wrapper > div:nth-child(5)
    {
        top: 60px;

        height: 73%;
        width: 100%;
    }

    .search-bar-input-wrapper > input
    {
        height: 54px;

        margin-bottom: 15px;

        text-align: center;

        border-radius: 8px;

        font-size: 16px;

        line-height: 22px;
    }

    .search-bar-input-wrapper > div
    {
        margin-bottom: 15px;

        justify-content: center;
        align-items: center;

        font-size: 16px;

        line-height: 22px;
    }

    .search-bar-input-wrapper > div > svg
    {
        margin-left: 10px;
    }

    .secteur-wrapper
    {
        height: 54px;
        width: 100%;

        border-radius: 8px;
    }

    .secteur-wrapper > div
    {
        top: 60px;

        width: 100%;
    }

    .lieu-wrapper
    {
        height: 54px;
        width: 100%;

        border-radius: 8px;
    }

    .lieu-wrapper > div
    {
        top: 60px;

        width: 100%;
    }

    .rayon-wrapper
    {
        height: 54px;
        width: 100%;

        border-radius: 8px;
    }

    .rayon-wrapper > div
    {
        top: 60px;

        height: 145px;
        width: 100%;
    }

    .search-bar-wrapper > button
    {
        width: 100%;

        min-height: 54px;

        margin-top: 22px;

        border-radius: 5px;

        margin: 0px;
    }
}