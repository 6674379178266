.button-wrapper
{
    height: 59px;
    width: 100%;

    max-height: 59px;
    min-height: 59px;

    border-radius: 6px;

    border: none;

    font-size: 19px;
    line-height: 26px;

    font-family: "Next Demi Bold";

    cursor: pointer;
}

.button-wrapper:hover
{
    filter: brightness(120%);
}

.button-fill-wrapper
{
    color: white;

    background-color: #FA8E20;
}

.button-not-fill-wrapper
{
    background: none;

    color: black;

    border: 0.5px solid #000000;
}