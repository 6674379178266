.profile-view-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    padding: 150px 50px 30px 50px;
}

.profile-center
{
    height: 95%;
    width: 75%;

    border-radius: 4px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    overflow-x: scroll;

    display: flex;

    justify-content: space-between;
    align-items: center;

    background-color: white;
}

.bills-loading
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.bills-list-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.bills-list-wrapper > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    line-height: 25px;

    color: #88888865;

    margin-bottom: 15px;
}

.menu
{
    height: 100%;
    width: 350px;

    min-width: 350px;

    display: flex;

    justify-self: center;
    align-items: flex-start;
    flex-direction: column;

    overflow-y: scroll;
}

.menu > p
{
    font-size: 30px;

    line-height: 41px;

    color: black;

    font-family: 'Next Demi Bold';

    margin: 30px;
}

.profile-menu-view
{
    height: 100%;
    width: 100%;

    display: flex;
    
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    border-left: 0.5px solid #c4c4c4b1;

    padding: 30px 50px;
}

.profile-menu-header
{
    height: 70px;
    width: 100%;

    min-height: 70px;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    border-bottom: 0.5px solid #c4c4c4b1;

    margin-bottom: 20px;
}

#header-title-profile
{
    font-size: 18px;

    font-family: 'Next Demi Bold';

    line-height: 25px;

    margin-right: auto;
}

#header-more-title-profile
{
    font-size: 17px;

    font-family: 'Next Medium';

    line-height: 23px;

    text-decoration: underline;

    cursor: pointer;
}

#header-more-title-profile:hover
{
    color: #FA8E20;
}

.profile-menu-header > button
{
    height: 43px;
    width: 43px;

    border: 1px solid #C3C3C3;

    border-radius: 8px;

    display: flex;

    justify-content: center;
    align-items: center;

    margin-right: 30px;

    background: none;

    cursor: pointer;
}

.profile-help
{
    height: 100%;
    width: 100%;

    padding-right: 30%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    overflow-y: scroll;
}

.profile-help > *
{
    margin-bottom: 20px;
}

.profile-help-area
{
    height: auto;
    width: 100%;
}

.profile-help-area > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    line-height: 25px;

    color: #88888865;
}

.profile-help-area > textarea
{
    height: 151px;
    width: 100%;

    border: none;

    outline: none;

    resize: none;

    padding: 20px;

    margin-top: 18px;

    color: black;

    font-size: 20px;

    line-height: 27px;

    font-family: "Next Medium";

    background-color: #F7F7F7;
}

.profile-help-area > textarea::placeholder
{
    font-size: 20px;

    font-family: 'Next Medium';

    line-height: 27px;

    color: #88888865;
}

.profile-business
{
    height: 100%;
    width: 100%;

    padding-right: 30%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    overflow-y: scroll;
}

.profile-business > *
{
    margin-bottom: 20px;
}

.profile-business-upload
{
    height: 196px;
    width: 100%;

    margin-bottom: 70px;
}

.profile-business-upload > p:nth-child(1)
{
    margin-bottom: 20px;

    font-size: 18px;

    font-family: 'Next Medium';

    line-height: 25px;

    color: #88888865;
}

.profile-business-upload > label > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    white-space: nowrap;

    line-height: 25px;

    color: #88888865;
}

.profile-business-upload > label
{
    height: 196px;
    width: 100%;

    cursor: pointer;

    border-radius: 7px;

    display: flex;

    justify-content: space-between;
    align-items: center;

    font-size: 18px;

    padding: 10px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';

    border: 1px dashed #707070;
}

.profile-business-upload > label > div
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-business-upload > label > div > p
{
    margin-top: 15px;
}

.profile-business-upload > label > img
{
    height: 100%;

    max-width: 148px;
    
    flex: 0.9;

    border-radius: 10px;
}

.profile-business-upload > label > input
{
    display: none;
}

.profile-more-item
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    margin-bottom: 20px;
}

.profile-more-item > p
{
    font-size: 21px;

    font-family: 'Next Demi Bold';

    line-height: 29px;

    margin-bottom: 10px;
}

.profile-more-item > div
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;
}

.profile-more-item > div > p
{
    font-size: 18px;

    font-family: 'Next Regular';

    line-height: 25px;

    max-width: 60%;

    color: #A0A0A0;
}

.profile-more-item > div > button
{
    font-size: 19px;

    font-family: 'Next Demi Bold';

    line-height: 26px;

    background: none;
    border: none;

    cursor: pointer;
}

.profile-more-item > div > button:hover
{
    color: #FA8E20;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
{ 
    -webkit-appearance: none;
    -moz-appearance: none;

    appearance: none;

    margin: 0px;
}

@media screen and (max-width: 800px)
{
    .profile-view-wrapper
    {
        padding: 110px 15px 15px 15px;
    }

    .profile-menu-view
    {
        padding: 15px;

        border-left: none;
    }

    .profile-more-item
    {
        border-bottom: 1px solid #C4C4C4;

        padding-bottom: 20px;
    }

    .profile-more-item > div
    {
        align-items: flex-start;
        flex-direction: column;
    }

    .profile-more-item > div > p
    {
        max-width: 100%;

        margin-bottom: 10px;
    }

    .profile-center
    {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1300px)
{
    .menu
    {
        width: 100%;
    }

    .profile-help
    {
        padding-right: 0px;
    }

    .profile-business
    {
        width: 100%;

        padding-right: 0px;
    }
}