.register-view-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    padding: 22px;
}

.register-information
{
    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: "Next Medium";
}

.register-upload-profile-wrapper
{
    height: 196px;
    width: 100%;

    margin-bottom: 50px;
}

.register-upload-profile-wrapper > p
{
    margin-bottom: 20px;

    font-size: 18px;

    font-family: 'Next Medium';

    line-height: 25px;

    color: #88888865;
}

.register-upload-profile-wrapper > label
{
    height: 100%;
    width: 100%;

    border: 1px dashed #707070;

    border-radius: 10px;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    overflow: hidden;

    cursor: pointer;
}

.register-upload-profile-wrapper > label > input
{
    display: none;
}

.register-upload-profile-wrapper > label > p
{
    font-size: 18px;

    font-family: 'Next Medium';

    white-space: nowrap;

    line-height: 25px;

    color: #88888865;
}

.cgvu-wrapper
{
    height: 70px;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    font-size: 18px;

    font-family: 'Next Medium';

    color: #88888865;
}

@media screen and (max-width: 800px)
{
    .register-view-wrapper
    {
        background-color: white;

        display: block;
    }

    .register-information
    {
        display: none;
    }

    .register-upload-profile-wrapper > label > p
    {
        font-size: 12px;
    }
}