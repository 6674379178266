.create-view-wrapper
{
    height: 100%;
    width: 100%;
}

.step-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding-top: 140px;
}

.create-form
{
    padding: 20px 30px;

    border-radius: 6px;

    filter: drop-shadow(15px 15px 70px #00000010);

    background-color: white;
}

.create-form > *
{
    margin: 10px 0px;
}

.step-wrapper > p:nth-child(1)
{
    font-size: 18px;

    line-height: 25px;

    color: #888888;

    letter-spacing: 7px;

    margin-bottom: 10px;

    font-family: 'Next Demi Bold';
}

.step-wrapper > p:nth-child(2)
{
    font-size: 40px;

    line-height: 55px;

    color: black;

    margin-bottom: 20px;

    font-family: 'Next Bold';
}

.step-1-form
{
    height: auto;
    width: 594px;

    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.step-1-form > p
{
    align-self: flex-start;

    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';
}

.step-1-form > div:nth-child(2) > input
{
    height: 151px;
}

.step-1-form > textarea
{
    height: 120px;
    width: 100%;

    padding: 15px;

    border-radius: 7px;

    outline: none;

    border: none;

    resize: none;

    align-self: flex-start;

    font-size: 18px;

    line-height: 25px;

    color: black;

    font-family: 'Next Medium';

    background-color: #F7F7F7;
}

.step-1-form > textarea::placeholder
{
    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';
}

.step-2-form
{
    height: auto;
    width: 594px;

    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.step-2-form > p
{
    align-self: flex-start;

    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';
}

.step-2-form > label
{
    height: 196px;
    width: 100%;

    cursor: pointer;

    border-radius: 7px;

    display: flex;

    justify-content: space-between;
    align-items: center;

    font-size: 18px;

    padding: 10px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';

    border: 1px dashed #707070;
}

.step-2-form > input
{
    display: none;
}

.step-2-form > div:hover
{
    color: #FA8E20;
}

.step-2-form > label > div
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.step-2-form > label > img
{
    height: 100%;
    
    flex: 0.9;

    border-radius: 10px;

    background-color: #88888865;
}

.step-2-form > label > div > p
{
    margin-top: 15px;
}

.step-3-form
{
    height: auto;
    width: 878px;
}

.step-3-form > p
{
    align-self: flex-start;

    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: 'Next Medium';
}

.step-3-form > div
{
    height: auto;
    width: 100%;

    display: flex;

    flex-wrap: wrap;
}

.step-3-form > input
{
    display: none;
}

.step-3-form > div > label
{
    height: 196px;
    width: 220px;

    cursor: pointer;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 7px;

    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    margin-right: 20px;
    margin-bottom: 20px;

    font-family: 'Next Medium';

    text-align: center;

    border: 1px dashed #707070;
}

.make-img
{
    height: 196px;
    width: 220px;

    margin-right: 20px;

    position: relative;
}

.make-img > svg
{
    position: absolute;

    top: 10px;
    right: 10px;

    cursor: pointer;
}

.make-img > img
{
    height: 100%;
    width: 100%;

    border-radius: 7px;
}

.step-3-form > div > label > p
{
    margin-top: 15px;
}

.step-3-form > div > label:hover
{
    color: #FA8E20;
}


.step-4-form
{
    height: auto;
    width: 594px;
}

.setp_4_drop_wrapper
{
    height: auto;
    width: 100%;

    margin-top: 10px;
}

.setp_4_drop_items_wrapper
{
    height: 150px;
    width: 90%;

    background-color: white;

    z-index: 10;

    overflow-x: scroll;

    margin-top: 10px;

    position: absolute;
}

.setp_4_drop_wrapper > p
{
    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: "Next Medium";

    margin-bottom: 10px;
}

.setp_4_drop_wrapper > input
{
    height: 64px;
    width: 100%;

    border-radius: 7px;

    border: none;

    color: black;

    font-size: 20px;

    line-height: 27px;

    outline: none;

    padding-left: 20px;

    font-family: "Next Medium";

    background-color: #F7F7F7;
}

.setp_4_drop_wrapper > div
{

}

.step-5-form
{
    height: auto;
    width: 594px;
}

.step-5-form > div:nth-child(1)
{
    height: auto;
    width: 100%;

    padding-bottom: 15px;

    display: flex;

    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #D6D6D6;
}

.step-5-form > div:nth-child(1) > p:nth-child(1)
{
    font-size: 18px;

    font-family: 'Next Medium';
    
    line-height: 25px;

    color: #88888865;
}

.step-5-form > div:nth-child(1) > p:nth-child(2)
{
    font-size: 17px;

    font-family: 'Next Medium';
    
    line-height: 23px;

    color: #000000;
}

.step-5-form > div:nth-child(3)
{
    height: auto;
    width: 100%;

    padding-bottom: 15px;

    border-bottom: 1px solid #D6D6D6;
}

.step-5-form > div:nth-child(3) > p:nth-child(1)
{
    font-size: 18px;

    font-family: 'Next Medium';
    
    line-height: 25px;

    margin-bottom: 5px;

    color: #88888865;
}

.step-5-form > div:nth-child(3) > p:nth-child(2)
{
    font-size: 17px;

    font-family: 'Next Medium';
    
    line-height: 23px;

    color: #000000;
}

.step-5-form > p
{
    font-size: 18px;

    font-family: 'Next Regular';
    
    line-height: 25px;

    margin: 20px 0px;

    color: #88888865;
}

.step-6-form
{
    height: auto;
    width: 594px;
}

.step-6-form > p:nth-child(1)
{
    font-size: 21px;

    line-height: 29px;

    color: #000000;

    font-family: 'Next Demi Bold';
}

.step-6-form > p:nth-child(2)
{
    font-size: 18px;

    line-height: 25px;

    color: #A0A0A0;

    font-family: 'Next Regular';
}

.step-7-form
{
    height: auto;
    width: 594px;
}

@media screen and (max-width: 800px)
{
    .make-img
    {
        width: 100%;

        height: auto;

        margin-bottom: 20px;
    }

    .step-3-form > div
    {
        display: block;
    }

    .create-view-wrapper
    {
        background-color: white;
    }

    .create-form
    {
        filter: none;

        background-color: white;

        width: 100%;
    }

    .step-wrapper
    {
        padding-top: 90px;
    }

    .step-wrapper > p:nth-child(1)
    {
        font-size: 12px;

        line-height: 16px;

        letter-spacing: 3px;

        font-family: 'Next Demi Bold';
    }

    .step-3-form > div > label
    {
        width: 100%;
    }

    .step-wrapper > p:nth-child(2)
    {
        font-size: 25px;

        line-height: 34px;

        font-family: 'Next Bold';
    }

    .step-2-form > label > img
    {
        max-width: 60%;
    }

    .step-2-form > label > div > p
    {
        text-align: center;
    }
}