.login-view-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    padding: 22px;
}

.login-register-wrapper
{
    height: 198px;
    width: 70%;

    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    padding: 30px;

    cursor: pointer;

    background-color: white;
}

.login-register-wrapper > p:nth-child(1)
{
    font-size: 21px;

    line-height: 29px;

    font-weight: bold;

    font-family: "Next Medium";

    color: black;
}

.login-register-wrapper > p:nth-child(2)
{
    font-size: 18px;

    line-height: 25px;

    font-family: "Next Medium";

    color: #A0A0A0;

    max-width: 70%;
}

.login-register-wrapper > p:nth-child(3)
{
    font-size: 19px;

    line-height: 26px;

    font-family: "Next Medium";

    font-weight: bold;

    color: black;
}

@media screen and (max-width: 800px)
{
    .login-view-wrapper
    {
        background-color: white;

        display: block;
    }

    .login-register-wrapper
    {
        width: 100%;

        padding-left: 0px;

        filter: none;

        border-top: 1px solid #B9B9B9;
    }

    .login-register-wrapper > p:nth-child(2)
    {
        max-width: 100%;
    }
}