.my-ads-wrapper
{
    height: auto;
    width: 95%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    position: relative;

    padding: 20px;

    border-radius: 14px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    margin-bottom: 45px;
    
    background-color: white;
}

.my-ads-wrapper > img
{
    height: 100%;
    width: 100%;

    max-height: 350px;
    max-width: 483px;

    object-fit: cover;

    border-radius: 7px;
}

.my-ads-information-wrapper
{
    height: 100%;
    width: 100%;

    margin-left: 30px;

    border-left: 0.5px solid #B9B9B9;

    padding: 30px 50px 20px 30px;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.my-ads-type-status
{
    height: 51px;
    width: 171px;

    position: absolute;

    top: 20px;
    right: 20px;

    display: flex;

    justify-content: center;
    align-items: center;

    font-size: 16px;

    line-height: 22px;

    border-radius: 8px;

    font-family: "Next Medium";

    background-color: #FEDDBB29;
}

.my-ads-text-information
{
    height: auto;
    width: 100%;

    margin-bottom: 10px;
    margin-top: 20px;
}

.my-ads-text-information > p:nth-child(1)
{
    font-size: 21px;

    line-height: 29px;

    font-family: "Next Demi Bold";

    margin-bottom: 15px;
}

.my-ads-text-information > p:nth-child(2)
{
    max-width: 95%;

    font-size: 18px;

    line-height: 25px;

    margin-bottom: 10px;

    color: #A0A0A0;

    font-family: "Next Regular";
}

.my-ads-info-wrapper
{
    display: flex;

    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;

    position: relative;

    font-size: 18px;

    color: #7C7C7C;

    text-overflow: ellipsis;

    font-family: "Next Medium";
}

.my-ads-info-wrapper > p
{
    margin-right: 15px;
    margin-bottom: 10px;
}

.my-ads-info-wrapper > p:nth-child(6)
{
    color: black;

    font-size: 17px;

    line-height: 23px;

    font-family: "Next Medium";

    text-decoration: underline;

    cursor: pointer;
}


.my-ads-info-wrapper > p:nth-child(6):hover
{
    color: #FA8E20;
}

.my-ads-info-wrapper > span
{
    height: 20px;
    width: 1px;

    margin-right: 20px;

    background-color: #B9B9B9;
}

.my-ads-buttons-wrapper
{
    height: auto;
    width: auto;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;

    margin-top: 20px;
}

.my-ads-buttons-wrapper > div
{
    height: 60px;
    width: 1px;

    margin: 0px 20px 15px 20px;

    background-color: #B9B9B9;
}

.my-ads-buttons-wrapper > button:nth-child(1)
{
    border: 1px solid #C3C3C3;

    padding: 15px 20px;

    border-radius: 6px;

    background: none;
}

.my-ads-buttons-wrapper > button:nth-child(1):hover
{
    background-color: #FA8E20;

    border: 1px solid white;

    color: white;
}

.my-ads-buttons-wrapper > button
{
    color: black;

    font-size: 19px;

    line-height: 26px;

    border: none;

    background: none;

    cursor: pointer;

    font-family: "Next Demi Bold";
}

.my-ads-buttons-wrapper > button:hover
{
    color: #FA8E20;
}

@media screen and (max-width: 1300px)
{
    .my-ads-wrapper
    {
        height: auto;

        padding: 5px;

        border-radius: 5px;

        flex-direction: column;
    }

    .my-ads-wrapper > img
    {
        display: none;
    }

    .my-ads-information-wrapper
    {
        border: none;

        padding: 15px 20px;

        margin: 0px;
    }
}

@media screen and (max-width: 800px)
{
    .my-ads-wrapper > img
    {
        width: 100%;

        max-width: 100%;

        display: block;
    }

    .my-ads-text-information > p:nth-child(1)
    {
        font-size: 17px;

        line-height: 23px;

        font-family: "Next Demi Bold";
    }

    .my-ads-text-information > p:nth-child(2)
    {
        font-size: 16px;

        max-width: 100%;

        line-height: 22px;
    }

    .my-ads-info-wrapper
    {
        font-size: 16px;

        line-height: 22px;
    }

    .my-ads-info-wrapper > div
    {
        height: 30px;
    }

    .my-ads-info-wrapper > p:nth-child(6)
    {
        font-size: 16px;

        line-height: 22px;
    }
}