.chat-header
{
    height: 87px;
    width: 95%;

    min-height: 87px;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding: 0px 20px 0px 20px;

    border-bottom: 1px solid #C4C4C4;
}

.chat-header-info
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.chat-header-info > button
{
    height: 43px;
    width: 43px;

    border: 1px solid #000000;

    margin-right: 20px;

    cursor: pointer;

    background: none;

    border-radius: 8px;

    display: none;
}

.chat-header-info > button:hover
{
    border: 1px solid #FA8E20;
}

.chat-header-data > p
{
    font-size: 23px;

    line-height: 31px;

    font-family: 'Next Demi Bold';
}

.chat-header-data
{
    height: auto;
    width: auto;

    margin-left: 20px;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.chat-header-name
{
    height: auto;
    width: auto;

    display: flex;

    justify-content: center;
    align-items: center;
}

.chat-header-name > p
{
    margin-left: 10px;

    font-size: 15px;

    line-height: 20px;

    font-family: 'Next Medium';

    cursor: pointer;

    text-decoration: underline;
}

.chat-header-name > p:hover
{
    color: #FA8E20;
}

.chat-header-menu
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chat-header-menu > div
{
    position: relative;
}

.chat-header-menu > div > button
{
    height: 43px;
    width: 43px;

    border: 1px solid #000000;

    border-radius: 8px;

    display: flex;

    justify-content: center;
    align-items: center;

    background: none;

    cursor: pointer;
}

.chat-header-menu > div > button:hover
{
    border: 1px solid #FA8E20;
}

.menu-text
{
    display: none;

    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    font-size: 15px;

    line-height: 20px;

    font-family: 'Next Medium';

    cursor: pointer;
}

@media screen and (max-width: 1300px)
{
    .chat-header-menu > div > button
    {
        display: none;
    }

    .chat-header-menu > div > div
    {
        display: flex;
    }
}

@media screen and (max-width: 800px)
{
    .chat-header
    {
        padding: 0px;
    }

    .chat-header-name > p
    {
        display: none;
    }
    
    .chat-header-info > button
    {
        display: flex;

        justify-content: center;
        align-items: center;

        margin-right: 10px;
    }

    .chat-header-data > p
    {
        font-size: 17px;

        line-height: 23px;
    }

    .chat-header-data
    {
        margin-left: 10px;
    }

    .menu-text
    {
        font-size: 13px;

        line-height: 19px;
    }
}

@media screen and (max-width: 400px)
{
    .chat-header-info > div:nth-child(2)
    {
        display: none;
    }
}