.avatar
{
    height: 55px;
    width: 55px;

    min-width: 55px;
    min-width: 55px;

    border-radius: 10px;

    background-color: tomato;
}

.avatar > img
{
    height: 100%;
    width: 100%;

    border-radius: 10px;
}

@media screen and (max-width: 800px)
{
    .avatar
    {
        height: 44px;
        width: 44px;
        
        min-width: 44px;
        min-width: 44px;
    }
}