.ads-wrapperr
{
    height: 440px;
    width: 95%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    position: relative;

    padding: 20px;

    border-radius: 14px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    margin-bottom: 45px;
    
    background-color: white;
}

.ads-wrapperr > div:nth-child(1)
{
    height: 100%;
    width: 45%;

    border-radius: 7px;
}

.ads-wrapperr > div:nth-child(1) > img
{
    height: 100%;
    width: 100%;

    border-radius: 7px;

    object-fit: cover;
}

.ads-information-wrapperr
{
    height: 100%;
    width: 100%;

    margin-left: 30px;

    border-left: 0.5px solid #B9B9B9;

    display: flex;

    justify-content: center;
    align-items: center;
}

.ads-type-status
{
    height: 51px;
    width: 147px;

    position: absolute;

    top: 20px;
    right: 20px;

    display: flex;

    justify-content: center;
    align-items: center;

    font-size: 16px;

    line-height: 22px;

    border-radius: 8px;

    font-family: "Next Medium";

    background-color: #FEDDBB29;
}

.custom-ads-information
{
    /* height: 100%; */
    width: 100%;

    padding: 30px 50px 40px 50px;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.report-button-wrapper
{
    height: auto;
    width: auto;

    position: absolute;

    bottom: 10px;
    right: 20px;
}

.report-button
{
    border: none;

    background: none;

    font-family: "Next Medium";

    font-size: 17px;

    line-height: 23px;

    margin-bottom: 10px;

    text-decoration: underline;

    cursor: pointer;
}

.report-button:hover
{
    color: #FA8E20;
}

.ads-profilee
{
    width: 100%;

    min-height: 77px;

    overflow: hidden;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.ads-profilee > div:nth-child(1)
{
    height: 77px;
    width: 77px;

    min-width: 77px;

    border-radius: 9px;

    margin-right: 30px;

    background-color: palegreen;
}

.ads-profilee > div:nth-child(1) > img
{
    height: 100%;
    width: 100%;

    object-fit : cover;

    border-radius: 7px;
}

.ads-profilee > div:nth-child(2)
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.ads-profilee > div:nth-child(2) > p
{
    font-family: "Next Demi Bold";

    font-size: 34px;

    white-space: nowrap;

    text-overflow: ellipsis;

    line-height: 46px;
}

.ads-profilee > div:nth-child(2) > div
{
    height: 100%;
    width: auto;

    display: flex;
    
    justify-content: center;
    align-items: center;
}

.ads-profilee > div:nth-child(2) > div > p
{ 
    font-family: "Next Medium";

    font-size: 17px;

    line-height: 23px;

    text-decoration: underline;

    cursor: pointer;
}

.ads-profilee > div:nth-child(2) > div > p:hover
{
    color: #FA8E20;
}

.ads-button-wrapper
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.text-info-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.text-info-wrapper > p:nth-child(1)
{
    font-family: "Next Demi Bold";

    font-size: 21px;

    line-height: 29px;

    margin-bottom: 10px;
}

.text-info-wrapper > p:nth-child(2)
{
    font-family: "Next Regular";

    font-size: 18px;

    line-height: 25px;

    color: #A0A0A0;

    max-width: 90%;
}

.ads-button
{
    border: none;
    
    background: none;

    margin-left: 30px;

    font-family: "Next Demi Bold";

    font-size: 19px;

    line-height: 26px;

    cursor: pointer;
}

.ads-button:hover, .ads-button > svg:hover
{
    color: #FA8E20;

    fill: #FA8E20;
}

.ads-button > svg
{
    margin-left: 10px;
}

.chat-button
{
    height: 59px;
    width: 235px;

    color: white;

    font-size: 19px;

    line-height: 26px;

    font-family: "Next Demi Bold";

    cursor: pointer;

    border: none;

    border-radius: 6px;
}

.chat-button:hover
{
    filter: brightness(110%);
}

@media screen and (max-width: 1300px)
{
    .ads-wrapperr
    {
        height: auto;
        width: 100%;

        padding: 5px;

        border-radius: 5px;

        flex-direction: column;
    }

    .ads-wrapperr  > div:nth-child(1)
    {
        width: 100%;
    }

    .ads-information-wrapperr
    {
        border: none;

        margin-left: 0px;
    }

    .custom-ads-information
    {
        display: flex;

        justify-content: center;
        align-items: center;

        padding: 20px 20px 0px 20px;
    }

    .ads-profilee > div:nth-child(1)
    {
        height: 57px;
        width: 57px;

        min-width: 57px;

        margin-right: 20px;
    }

    .ads-profilee
    {
        min-height: 57px;

        margin-bottom: 10px;
    }

    .ads-profilee > div:nth-child(2) > p
    {
        font-family: "Next Demi Bold";

        font-size: 23px;

        line-height: 31px;
    }

    .ads-profilee > div:nth-child(2) > div > p
    {   
        font-size: 13px;

        line-height: 18px;
    }

    .text-info-wrapper > p:nth-child(1)
    {
        font-family: "Next Demi Bold";

        font-size: 17px;

        line-height: 23px;

        max-width: 100%;

        margin-top: 5px;
    }

    .text-info-wrapper > p:nth-child(2)
    {
        font-family: "Next Regular";

        font-size: 16px;

        line-height: 22px;

        margin-top: 5px;
    }

    .ads-button-wrapper
    {
        margin-top: 30px;

        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ads-button
    {
        margin-top: 20px;
        margin-left: 0px;
    }

    .report-button-wrapper
    {
        height: auto;
        width: 100%;

        position: relative;

        bottom: 0px;
        right: 0px;

        display: flex;

        justify-content: center;
        align-items: center;

        margin-top: 20px;

        padding: 30px 0px 20px 0px;

        border-top: 0.5px solid #B9B9B9;
    }

    .chat-button
    {
        height: 54px;
        width: 200px;

        font-size: 16px;

        line-height: 22px;
    }
}