.cgu-wrapper
{
    height: 100vh;
    width: 100vw;

    padding: 143px 20px;
}

.cgu-wrapper > h3
{
    text-align: center;
    
    font-family: "Next Medium";

    font-size: 25px;
}

.article
{
    margin: 20px 0px;

    font-family: "Next Medium";

    font-size: 20px;
}

.text
{
    margin: 20px 0px;

    font-family: "Next Medium";

    color: #00000075;
}

@media screen and (max-width: 800px)
{
    .cgu-wrapper
    {
        padding-top: 120px;
    }

    .cgu-wrapper > h3
    {
        font-size: 17px;
    }

    .article
    {
        font-size: 15px;
    }
}