.search-section
{
    height: 610px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    padding: 130px 100px 100px 100px;
    
    margin-bottom: 50px;

    border-radius: 14px;

    background-image: url("../../assets/home/home-search.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.search-section > p:nth-child(1)
{
    font-size: 20px;

    line-height: 30px;

    color: #DEDEDE;

    font-family: "Next Demi Bold";
}

.search-section > p:nth-child(2)
{
    font-size: 37px;

    line-height: 68px;

    color: #FFFFFF;

    font-family: "Next Bold";

    margin-top: 10px;

    max-width: 100%;
}

.search-section > p:nth-child(4)
{
    font-size: 20px;

    line-height: 30px;

    color: #FFFFFF;

    font-family: "Next Demi Bold";
}

@media screen and (max-width: 1300px)
{
    .search-section
    {
        justify-content: center;
        align-items: center;

        padding: 20px;
        
        margin-bottom: 35px;
        
        border-radius: 5px;

        background-image: url("../../assets/home/home-search-mobile.png");
    }

    .search-section > p:nth-child(1)
    {
        font-size: 12px;

        line-height: 18px;

        color: #DEDEDE;

        font-family: "Next Demi Bold";
    }

    .search-section > p:nth-child(2)
    {
        font-size: 20px;

        line-height: 32px;

        color: #FFFFFF;

        text-align: center;

        margin-bottom: 30px;

        font-family: "Next Bold";

        max-width: 80%;
    }

    .search-section > p:nth-child(4)
    {
        font-size: 16px;

        line-height: 22px;

        color: white;

        font-family: "Next Demi Bold";
    }
}

@media screen and (max-width: 800px)
{
    .search-section
    {
        height: auto;
        
        justify-content: flex-start;
        align-items: flex-start;

        padding: 20px;
    }

    .search-section > p:nth-child(2)
    {
        margin-bottom: auto;

        text-align: left;

        max-width: 100%;
    }

    .search-section > p:nth-child(2) > br
    {
        display: none;
    }

    .search-section > p:nth-child(4)
    {
        display: none;
    }
}