.menu-item-wrapper
{
    height: 62px;
    width: 100%;

    min-height: 62px;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding-left: 30px;

    cursor: pointer;

    color: black;
}

.menu-item-wrapper > p
{
    font-size: 21px;

    line-height: 29px;
}

.menu-item-wrapper:hover
{
    color: #FA8E20;
}

.menu-item-wrapper-not-active
{
    font-family: 'Next Medium';
}

.menu-item-wrapper-not-active > p:hover
{
    color: #FA8E20;
}

.menu-item-wrapper-active
{
    background-color: #fedcbb46;

    font-family: 'Next Demi Bold';
}

.menu-item-wrapper-active:hover
{
    color: black;
}