.profile-modal-wrapper
{
    height: 179px;
    width: 294px;

    position: absolute;

    top: 80%;
    right: 40px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    padding-left: 25px;

    background-color: white;
}

.profile-modal-wrapper > div
{
    height: 55px;
    width: 90%;

    border-bottom: 1px solid #D6D6D6;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    margin-bottom: 15px;
}

.profile-modal-wrapper > div > p
{
    font-size: 17px;

    line-height: 23px;

    color: #B2B2B2;

    font-family: 'Next Medium';
}

.profile-modal-wrapper > p
{
    font-size: 18px;

    line-height: 25px;

    color: black;

    margin-bottom: 5px;

    font-family: 'Next Medium';

    cursor: pointer;
}

.profile-modal-wrapper > p:hover
{
    color: #FA8E20;
}

@media screen and (max-width: 1300px)
{
    .profile-modal-wrapper
    {
        top: -80%;
        right: 40px;
    }
}