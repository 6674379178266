.drop-wrapper
{
    height: auto;
    width: 100%;

    position: relative;
}

.drop-wrapper > p
{
    font-size: 18px;

    line-height: 25px;

    color: #88888865;

    font-family: "Next Medium";

    margin-bottom: 10px;
}

.drop-wrapper > button
{
    height: 64px;
    width: 100%;

    border-radius: 7px;

    border: none;

    color: black;

    font-size: 20px;

    line-height: 27px;

    outline: none;

    padding: 0px 20px;

    font-family: "Next Medium";

    display: flex;

    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    background-color: #F7F7F7;
}

.drop-wrapper > div
{
    height: auto;
    width: 100%;

    max-height: 200px;

    z-index: 10;

    position: absolute;

    top: 110%;
    left: 0px;

    overflow-y: scroll;

    padding: 15px 0px 0px 15px;

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    background-color: #F7F7F7;
}

.drop-wrapper > div > p
{
    font-size: 18px;

    line-height: 25px;

    color: black;

    font-family: "Next Medium";

    margin-bottom: 18px;

    cursor: pointer;
}

.drop-wrapper > div > p:hover
{
    color: #FA8E20;
}