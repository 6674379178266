.view-wrapper
{
    flex: 1;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding: 160px 70px 0px 70px;
}

.view-header
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    margin-bottom: 15px;

    padding: 0px 60px;
}

.view-header > button
{
    height: 59px;
    width: 59px;

    display: flex;

    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: none;

    border-radius: 8px;

    margin-right: 20px;

    border: 1px solid #C3C3C3;
}

.view-header > p
{
    font-size: 22px;

    font-family: 'Next Medium';

    color: #B7B7B7;

    line-height: 30px;
}

.view-profile
{
    width: 100%;

    min-height: 77px;

    overflow: hidden;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.view-profile > div:nth-child(1)
{
    height: 77px;
    width: 77px;

    min-width: 77px;

    border-radius: 9px;

    margin-right: 30px;

    background-color: palegreen;
}

.view-profile > div:nth-child(1) > img
{
    height: 100%;
    width: 100%;

    object-fit: cover;

    border-radius: 7px;
}

.view-profile > div:nth-child(2)
{
    height: 100%;
    width: auto;

    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.view-profile > div:nth-child(2) > p
{
    font-family: "Next Demi Bold";

    font-size: 34px;

    white-space: nowrap;

    text-overflow: ellipsis;

    line-height: 46px;
}

.view-profile > div:nth-child(2) > div
{
    height: 100%;
    width: auto;

    display: flex;
    
    justify-content: center;
    align-items: center;
}

.view-profile > div:nth-child(2) > div > p
{
    font-family: "Next Medium";

    font-size: 17px;

    line-height: 23px;

    text-decoration: underline;

    cursor: pointer;
}

.view-profile > div:nth-child(2) > div > p:hover
{
    color: #FA8E20;
}

.view-information
{
    height: 100%;
    width: 100%;

    padding: 30px 50px 40px 50px;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.view-ad-content
{
    height: 440px;
    width: 95%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    position: relative;

    padding: 20px;

    margin-bottom: 45px;

}

.view-ad-content > div:nth-child(1)
{
    height: 100%;
    width: 45%;

    border-radius: 7px;
}

.view-ad-content > div:nth-child(1) > img
{
    height: 100%;
    width: 100%;

    border-radius: 7px;

    object-fit: cover;
}

.view-table
{
    height: auto;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    padding: 0px 60px;

    margin-bottom: 40px;
}

#view-span-message
{
    text-decoration: underline;

    color: black;

    cursor: pointer;
}

#view-span-message:hover
{
    color: #FA8E20;
}

.view-table > p:nth-child(1)
{
    font-size: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;

    margin-bottom: 20px;
}

.view-table > div
{
    height: auto;
    width: 100%;

    padding: 10px 50px;

    border-radius: 11px;

    border: 0.5px solid #C3C3C3;
}

.view-table > div > div
{
    height: auto;
    width: 100%;

    display: flex;

    flex-wrap: wrap;

    justify-content: space-between;
    align-items: center;
}

.view-table > div > div:not(:last-child)
{
    border-bottom: 1px solid #C3C3C3;
}

.view-table > div > *
{
    padding: 30px 0px;
}

.view-table > div > * > p:nth-child(1)
{
    text-align: left;

    font-size: 23px;

    font-family: 'Next Demi Bold';

    line-height: 31px;
}

.view-table > div > * > p:nth-child(2)
{
    text-align: right;

    font-size: 23px;

    font-family: 'Next Medium';

    line-height: 31px;

    color: #A0A0A0;
}

.view-description
{
    height: auto;
    width: 100%;

    padding: 0px 60px;
}

.view-description > p:nth-child(1)
{
    font-size: 30px;

    font-family: 'Next Demi Bold';

    line-height: 41px;

    margin-bottom: 20px;
}

.view-description > p:nth-child(2)
{
    font-size: 20px;

    font-family: 'Next Regular';

    line-height: 27px;

    color: #A0A0A0;

    margin-bottom: 40px;
}

.view-report-button-wrapper
{
    height: auto;
    width: auto;

    position: absolute;

    bottom: 10px;
    right: 20px;
}

@media screen and (max-width: 1300px)
{
    .view-information
    {
        display: flex;

        justify-content: center;
        align-items: center;

        padding: 20px 20px 0px 20px;
    }

    .view-profile > div:nth-child(1)
    {
        height: 57px;
        width: 57px;

        min-width: 57px;

        margin-right: 20px;
    }

    .view-profile
    {
        min-height: 57px;

        margin-bottom: 10px;
    }

    .view-profile > div:nth-child(2) > p
    {
        font-family: "Next Demi Bold";

        font-size: 23px;

        line-height: 31px;
    }

    .view-report-button-wrapper
    {
        height: auto;
        width: 100%;

        position: relative;

        bottom: 0px;
        right: 0px;

        display: flex;

        justify-content: center;
        align-items: center;

        margin-top: 20px;

        padding: 30px 0px 20px 0px;

        border-top: 0.5px solid #B9B9B9;
    }
}

@media screen and (max-width: 1300px)
{
    .view-table
    {
        padding: 0px;
    }

    .view-table > div > div
    {
        justify-content: flex-start;
        align-items: flex-start;

        flex-direction: column;

        padding: 20px 0px;
    }

    .view-table > div
    {
        padding: 15px;
    }

    .view-table > div > div > p:nth-child(1)
    {
        text-align: left;

        font-size: 17px;

        line-height: 23px;

        margin-bottom: 10px;
    }

    .view-table > div > div > p:nth-child(2)
    {
        text-align: left;

        font-size: 16px;

        line-height: 22px;
    }

    .view-table > table > * > td
    {
        display: flex;

        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    .view-description
    {
        padding: 0px 10px;
    }

    .view-description > p:nth-child(1)
    {
        font-size: 20px;

        line-height: 27px;
    }

    .view-description > p:nth-child(2)
    {
        font-size: 16px;

        line-height: 22px;
    }

    .view-ad-content
    {
        height: auto;
        width: 100%;

        padding: 5px;

        border-radius: 5px;

        flex-direction: column;
    }

    .view-ad-content > div:nth-child(1)
    {
        width: 100%;
    }

    .view-wrapper
    {
        padding: 140px 15px 0px 15px;
    }

    .view-header
    {
        padding: 0px 10px;
    }

    .view-header > p
    {
        font-size: 18px;

        line-height: 24px;
    }

    .view-header > button
    {
        height: 44px;
        width: 44px;
    }
}

@media screen and (max-width: 800px)
{
    .view-wrapper
    {
        padding: 110px 15px 0px 15px;
    }
}