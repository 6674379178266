.loading-modal
{
    height: 100%;
    width: 100%;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    
    z-index: 5;

    display: flex;

    justify-content: center;
    align-items: center;

    background-color: #00000090;
}