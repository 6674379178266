.reset-view-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    padding: 22px;
}

.reset-title
{
    font-size: 21px;

    line-height: 29px;

    font-family: "Next Medium";

    font-weight: bold;

    align-self: flex-start;
}

.reset-information
{
    font-size: 18px;

    font-family: "Next Medium";

    line-height: 25px;
    
    color: #A0A0A0;

    align-self: flex-start;
}

@media screen and (max-width: 800px)
{
    .reset-view-wrapper
    {
        background-color: white;

        display: block;
    }

    .reset-information
    {
        font-size: 16px;
    }
}