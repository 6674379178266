*
{
    margin: 0px;
    padding: 0px;

    box-sizing: border-box;
}

body
{
    background-color: #F8F8F8;
}

.adresselisted
{
    height: 30px;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    white-space: nowrap;

    font-size: 18px;

    line-height: 25px;

    font-family: "Next Medium";

    margin-bottom: 18px;

    cursor: pointer;

    color: black;
}

a
{
    color: black;

    font-size: 19px;

    line-height: 26px;

    cursor: pointer;

    font-family: "Next Medium";
}

.invisible-scrollbar::-webkit-scrollbar
{
    display: none;
}

@font-face
{
    font-family: "Next Bold";

    src: url("./fonts/AvenirNextLTPro-Bold.otf");
}

@font-face
{
    font-family: "Next Regular";

    src: url("./fonts/AvenirNextLTPro-Regular.otf");
}

@font-face
{
    font-family: "Next Demi Bold";

    src: url("./fonts/AvenirNext-DemiBold.ttf");
}

@font-face
{
    font-family: "Next Medium";

    src: url("./fonts/Metropolis-Medium.otf");
}

/* APPLICATION */

.app-wrapper
{
    height: 100vh;
    width: 100vw;

    position: relative;
}

/* AUTHENTICATION */

.auth-image-wrapper
{
    height: 100%;
    width: 50%;
}

.auth-image-wrapper > img
{
    height: 100%;
    width: 100%;

    object-fit: cover;

    border-radius: 7px;

    animation: fadein 2s;
}

@keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

.auth-header-wrapper
{
    height: 100px;
    width: 75%;

    margin-bottom: 40px;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    border-bottom: 1px solid #B9B9B9;
}

.auth-header-wrapper > svg
{
    display: none;
}

.auth-header-wrapper > p:nth-child(1)
{
    color: #888888;

    font-size: 18px;

    line-height: 25px;

    font-family: "Next Demi Bold";
}

.auth-header-wrapper > p:nth-child(2)
{
    color: #000000;

    font-size: 40px;

    line-height: 55px;

    font-family: "Next Bold";
}

.auth-form-wrapper
{
    height: auto;
    width: 70%;

    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    margin-bottom: 40px;
    
    border-radius: 4px;

    padding: 30px 50px 10px 50px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    background-color: white;
}

.error-message
{
    color: red;

    font-size: 18px;

    font-family: "Next Medium";
}

.auth-right-scroll-wrapper
{
    margin: auto;

    max-height: 100%;

    width: 50%;

    overflow: auto;
}

.auth-right-wrapper
{
    flex: 1;

    margin: auto;

    display: flex;
    
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.auth-form-wrapper > *
{
    margin: 10px 25px;
}

.modal-wrapper
{
    height: 100%;
    width: 100%;

    position: fixed;

    left: 0px;
    top: 0px;

    display: flex;

    justify-content: center;
    align-items: center;

    z-index: 3;

    background-color: #00000069;
}

@media screen and (max-width: 1400px), (max-height: 500px)
{
    .auth-image-wrapper
    {
        display: none;
    }

    .auth-right-wrapper
    {
        width: 100%;
    }

    .auth-right-scroll-wrapper
    {
        width: 100%;
    }
}

@media screen and (max-width: 800px)
{
    body
    {
        background-color: #F8F8F8;
    }

    .auth-form-wrapper
    {
        width: 100%;
        
        padding: 30px 0px;

        filter: none;
    }

    .auth-form-wrapper > a
    {
        font-size: 16px;

        line-height: 22px;
    }

    .auth-header-wrapper
    {
        height: 55px;
        width: 100%;

        align-items: flex-start;
    }

    .auth-header-wrapper > p:nth-child(1)
    {
        display: none;
    }

    .auth-header-wrapper > p:nth-child(2)
    {
        font-size: 21px;

        line-height: 29px;
    }

    .auth-right-wrapper
    {
        justify-content: flex-start;
    }

    .auth-header-wrapper
    {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .auth-header-wrapper > svg
    {
        display: block;

        cursor: pointer;
    }
}

/* HOME */