.my-ads-view-wrapper
{
    height: auto;
    width: 100%;

    padding: 180px 40px 50px 40px;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.my-ads-view-title
{
    height: auto;
    width: 95%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 30px;
}

.my-ads-view-title > button
{
    height: auto;
    width: auto;

    border: none;

    color: white;

    padding: 15px 20px;

    border-radius: 6px;

    font-size: 19px;

    line-height: 26px;

    font-family: 'Next Demi Bold';

    cursor: pointer;

    background-color: #FA8E20;
}

.my-ads-view-title > button:hover
{
    filter: brightness(110%);
}

.my-ads-view-title > p
{
    font-size: 30px;

    line-height: 41px;

    font-family: 'Next Demi Bold';
}

@media screen and (max-width: 800px)
{
    .my-ads-view-wrapper
    {
        padding: 120px 0px 20px 0px;
    }

    .my-ads-view-title > p
    {
        font-size: 25px;

        line-height: 34px;
    }
}