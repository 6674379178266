.chat-modal
{
    height: auto;
    width: 294px;

    border-radius: 0px 0px 8px 8px;

    position: absolute;

    top: 50px;
    right: 0px;

    z-index: 5;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.080));

    padding: 20px 20px 5px 20px;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background-color: white;
}

.chat-modal > div:nth-child(1)
{
    height: auto;
    width: 90%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding-bottom: 10px;

    margin-bottom: 10px;

    border-bottom: 1px solid #B2B2B2;
}

.chat-modal > div:nth-child(1) > p
{
    font-size: 17px;

    line-height: 23px;

    color: #B2B2B2;

    font-family: 'Next Medium';
}

.chat-modal > div:nth-child(1) > button
{
    border: none;

    background: none;

    font-size: 16px;

    line-height: 22px;

    cursor: pointer;

    font-family: 'Next Demi Bold';

    color: #FA8E20;
}

.chat-modal > div:nth-child(1) > button:hover
{
    color: red;
}

.chat-modal > div:nth-child(2)
{
    height: auto;
    width: 90%;

    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.chat-modal > div:nth-child(2) > button
{
    border: none;

    background: none;

    font-size: 16px;

    line-height: 22px;

    cursor: pointer;

    font-family: 'Next Medium';

    margin-bottom: 10px;

    color: back;
}

.chat-modal > div:nth-child(2) > button:hover
{
    color: #FA8E20;
}