.types-wrapper
{
    height: 551px;
    width: 95%;

    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    margin-bottom: 50px;

    z-index: 1;
}

.types-header
{
    height: 10%;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;
}

.types-header > p:nth-child(1)
{
    font-size: 30px;

    line-height: 41px;

    font-family: "Next Demi Bold";
}

.types-header > p:nth-child(2)
{
    color: #B7B7B7;

    font-size: 25px;

    line-height: 34px;

    cursor: pointer;

    font-family: "Next Demi Bold";
}

.types-header > p:nth-child(2):hover
{
    color: orange;
}

.types-list-wrapper
{
    height: 85%;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding: 50px;

    filter: drop-shadow(15px 15px 70px rgba(0, 0, 0, 0.070));

    border-radius: 7px;

    position: relative;

    background-color: white;
}

.types-list-wrapper > div
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0px 0px 0px 20px;

    overflow-x: scroll;
    overflow-y: hidden;
}

.types-list-wrapper-more
{
    padding-right: 270px;
}

.types-list-wrapper > button
{
    height: 75%;
    width: 270px;

    min-width: 270px;

    margin-left: 10px;

    position: fixed;

    right: 0px;

    border: none;

    background-color: white;

    border-left: 0.5px solid #B9B9B9;

    cursor: pointer;
}

.types-list-wrapper > button > svg:hover
{
    fill: orange;
}

@media screen and (max-width: 1300px)
{
    .types-list-wrapper
    {   
        overflow-x: scroll;
        overflow-y: hidden;

        padding: 10px 10px 10px 0px;
    }

    .types-list-wrapper > button
    {
        display: none;
    }

    *::-webkit-scrollbar
{
    display: none;
}
}

@media screen and (max-width: 800px)
{
    .types-list-wrapper > div
    {
        padding-right: 0px;
    }

    .types-wrapper
    {
        height: 370px;

        margin-bottom: 20px;

        background: none;
    }

    .types-header > p:nth-child(1)
    {
        font-size: 20px;

        line-height: 27px;
    }

    .types-header > p:nth-child(2)
    {
        font-size: 16px;

        line-height: 22px;
    }

    .types-list-wrapper
    {
        background: none;
    }

    .types-list-wrapper > button
    {
        display: none;
    }
}