.card-payement-modal-wrapper
{
    height: 100%;
    width: 100%;

    max-width: 562px;
    max-height: 770px;

    border-radius: 5px;

    padding: 30px;

    overflow-y: scroll;

    background-color: white;
}

.card-payement-modal-wrapper > div:nth-child(1)
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;

    padding-bottom: 20px;

    margin-bottom: 20px;

    font-size: 21px;

    line-height: 29px;

    font-family: 'Next Demi Bold';

    border-bottom: 1px solid #D6D6D6;
}

.card-payement-modal-wrapper> div:nth-child(1) > svg
{
    cursor: pointer;
}

.card-payement-modal-wrapper > div:nth-child(2) > *
{
    margin-bottom: 20px;
}

@media screen and (max-width: 800px)
{
    .card-payement-modal-wrapper
    {
        max-height: 100%;
        max-width: 100%;
    }
}