.type-wrapper
{
    height: 351px;
    width: 400px;

    min-width: 363px;

    margin: 10px;

    margin-right: 30px;

    padding: 0px 40px 0px 0px;
}

.active-type
{
    border-right: 1px solid #B9B9B9;
}

.type-wrapper > img
{
    height: 60%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.type-wrapper > div
{
    height: auto;
    width: 100%;

    padding: 20px 0px 0px 0px;
}

.type-wrapper > div > p:nth-child(1)
{
    font-family: "Next Demi Bold";

    color: #000000;

    font-size: 23px;

    margin-bottom: 20px;

    line-height: 31px;
}

.button-wrapper-type
{
    height: auto;
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.button-wrapper-type > button:nth-child(1)
{
    border: none;

    background: none;

    font-family: "Next Demi Bold";

    font-size: 19px;

    line-height: 26px;

    color: #000000;

    cursor: pointer;
}

.button-wrapper-type > button:hover:nth-child(1)
{
    color: #FA8E20;
}

@media screen and (max-width: 800px)
{
    .type-wrapper
    {
        height: 297px;
        width: 279px;

        margin-right: 10px;

        min-width: 279px;

        border-radius: 10px;

        padding: 15px 15px 30px 15px;

        background-color: white;

        border: none;
    }

    .type-wrapper > div
    {
        padding-top: 10px;
    }

    .type-wrapper > img
    {
        border-radius: 5px;
    }

    .type-wrapper > div > p:nth-child(1)
    {
        font-size: 18px;

        line-height: 25px;

        margin-bottom: 20px;
    }

    .type-wrapper > div > p:nth-child(2)
    {
        font-size: 16px;

        line-height: 22px;

        margin-bottom: 5px;
    }

    .type-wrapper > div > button
    {
        font-size: 16px;

        line-height: 22px;
    }
}